import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
} from '../styles';
import { Button, Container } from './ui';
import ProductWarrantySearchBox from './ProductWarrantySearchBox';
import ProductWarrantySearchResult from './ProductWarrantySearchResult';

const StyledProductWarranty = styled.section`
  ${sectionPaddings()};
  background-color: ${standardColours.lightGrey};
`;

const StyledContainer = styled(Container)`
  max-width: 750px;
`;

const StyledHeading = styled.h1`
  text-align: center;
  font-weight: ${fontWeights.regular};
  ${fluidFontSize(
    '25px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  border-bottom: 1px solid ${standardColours.grey};
  padding-bottom: 20px;

  ${minBreakpointQuery.medium`
    padding-bottom: 30px;
  `}

  ${minBreakpointQuery.mlarge`
   padding-bottom: 40px;
  `}
`;

const StyledForm = styled.form`
  margin-top: 25px;
  display: grid;
  row-gap: 10px;

  ${minBreakpointQuery.medium`
    margin-top: 35px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 45px;
  `}
`;

const StyledLabelText = styled.span`
  display: block;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: ${fontWeights.semibold};
`;

const commonInputStyles = () => {
  return css`
    width: 100%;
    padding: 12px 16px;
    border: 1px solid ${standardColours.darkGrey};
    border-radius: 12px;
  `;
};

const StyledInput = styled.input`
  ${commonInputStyles()}
`;

const StyledErrorText = styled.p`
  ${fontSize(12)};
  color: red;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

const StyledSubmittedText = styled.p`
  margin-top: 25px;

  ${minBreakpointQuery.medium`
    margin-top: 35px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 45px;
  `}
`;

const ProductWarranty = ({ heading }) => {
  const [hasFocus, setFocus] = useState(false);
  const [show, setShow] = useState();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  const searchClient = {
    search(requests) {
      if (requests[0].params.query === '') {
        return [];
      }

      return algoliaClient.search(requests);
    },
  };

  const indices = [{ name: `Products`, title: `Products` }];

  const handleSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const product = formData.get('item-purchased');

    if (!product) {
      setError('Please select a product');
    } else {
      formData.delete('search-term');
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => setSubmitted(true))
        .catch(error => console.log(error));
    }
  };

  return (
    <StyledProductWarranty>
      <StyledContainer>
        <StyledHeading>{heading}</StyledHeading>
        {!submitted ? (
          <StyledForm
            name="product-warranty"
            method="POST"
            data-netlify="true"
            onSubmit={e => handleSubmit(e)}
          >
            <input type="hidden" name="form-name" value="product-warranty" />
            <StyledInput type="text" name="name" placeholder="Name" required />
            <StyledInput
              type="email"
              name="email"
              placeholder="Email"
              required
            />
            <StyledInput
              type="tel"
              name="phone"
              placeholder="Phone number"
              required
            />
            <label>
              <StyledLabelText>Date of purchase</StyledLabelText>
              <StyledInput type="date" name="purchase-date" required />
            </label>
            <label>
              <StyledLabelText>Retailer/place of purchase</StyledLabelText>
              <StyledInput
                type="text"
                name="place-of-purchase"
                placeholder="E.g. Amazon"
                required
              />
            </label>
            <div>
              <InstantSearch
                searchClient={searchClient}
                indexName={indices[0].name}
                onSearchStateChange={({ query }) => {
                  setSelectedProduct('');
                  setShow(query.length > 0);
                }}
              >
                <StyledLabelText>Item purchased</StyledLabelText>
                <ProductWarrantySearchBox
                  onFocus={() => {
                    setFocus(true);
                  }}
                  hasFocus={hasFocus}
                  selectedProduct={selectedProduct}
                />
                <ProductWarrantySearchResult
                  indices={indices}
                  show={show}
                  setSelectedProduct={setSelectedProduct}
                  setShow={setShow}
                  setError={setError}
                />
              </InstantSearch>
            </div>
            <input
              type="text"
              name="item-purchased"
              value={selectedProduct}
              hidden
            />
            {error && <StyledErrorText>{error}</StyledErrorText>}
            <StyledInput
              type="text"
              name="order-number"
              placeholder="Online order number"
              required
            />
            <StyledButton type="submit" alt={true}>
              Submit
            </StyledButton>
          </StyledForm>
        ) : (
          <StyledSubmittedText>
            Thanks for submitting your product warranty, we'll be in touch if we
            need anything else
          </StyledSubmittedText>
        )}
      </StyledContainer>
    </StyledProductWarranty>
  );
};

export default ProductWarranty;
