import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import ProductWarranty from '../components/ProductWarranty';

const ProductWarrantyPage = () => {
  const {
    datoCmsProductWarranty: { seoMetaTags, heading },
  } = useStaticQuery(graphql`
    query ProductWarrantyPageQuery {
      datoCmsProductWarranty {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        heading
      }
    }
  `);

  return (
    <Layout seo={seoMetaTags}>
      <main>
        <ProductWarranty heading={heading} />
      </main>
    </Layout>
  );
};

export default ProductWarrantyPage;
