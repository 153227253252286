import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Hits } from 'react-instantsearch-dom';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
} from '../styles';
import { ProductPrice } from './Prices';

const StyledProductWarrantySearchResult = styled.div`
  display: ${({ show }) => (show ? `block` : `none`)};
  padding: 10px;
  width: 100%;
  background-color: ${standardColours.white};
  border: 1px solid ${brandColours.quaternary};
  max-height: 230px;
  overflow-y: scroll;

  ul {
    display: grid;
    gap: 10px;
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

const StyledImage = styled(GatsbyImage)`
  min-width: 40px;
  max-width: 40px;

  ${minBreakpointQuery.small`
    min-width: 60px;
    max-width: 60px;
  `}

  ${minBreakpointQuery.large`
    min-width: 80px;
    max-width: 80px;
  `}
`;

const StyledContent = styled.div`
  margin-left: 20px;

  ${minBreakpointQuery.small`
    margin-left: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 30px;
  `}
`;

const StyledName = styled.p`
  margin-bottom: 4px;
  ${fontSize(16)};

  ${minBreakpointQuery.small`
    margin-bottom: 6px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 8px;
    ${fontSize(20)};
  `}
`;

const StyledPrice = styled(ProductPrice)`
  display: flex;
  gap: 10px;
`;

const PageHit = ({
  hit: {
    title,
    featuredImage: { gatsbyImageData, altText },
    priceRangeV2,
    variants,
  },
  setSelectedProduct,
  setShow,
  setError,
}) => (
  <StyledItem
    onClick={() => {
      setSelectedProduct(title);
      setShow(false);
      setError(null);
    }}
  >
    <StyledImage image={gatsbyImageData} alt={altText || title} />
    <StyledContent>
      <StyledName>{title}</StyledName>
      <StyledPrice priceRange={priceRangeV2} variants={variants} />
    </StyledContent>
  </StyledItem>
);

const ProductWarrantySearchResult = ({
  show,
  setSelectedProduct,
  setShow,
  setError,
}) => (
  <StyledProductWarrantySearchResult show={show}>
    <Hits
      hitComponent={({ hit }) => (
        <PageHit
          hit={hit}
          setSelectedProduct={setSelectedProduct}
          setShow={setShow}
          setError={setError}
        />
      )}
    />
  </StyledProductWarrantySearchResult>
);

export default ProductWarrantySearchResult;
