import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { standardColours, visuallyHidden } from '../styles';
import { Svg } from './ui';
import searchIcon from '../images/search-icon.inline.svg';

const StyledProductWarrantySearchBox = styled.div`
  position: relative;
  background-color: ${standardColours.white};
  border-radius: 12px;
`;

const StyledLabel = styled.label`
  ${visuallyHidden()};
`;

const StyledInput = styled.input`
  width: 100%;
  background: none;
  border: none;
  padding: 12px 16px 12px 32px;
  border: 1px solid ${standardColours.darkGrey};
  border-radius: 12px;

  ::-webkit-input-placeholder {
    color: ${standardColours.black};
  }

  ::-moz-placeholder {
    color: ${standardColours.black};
  }

  :-ms-input-placeholder {
    color: ${standardColours.black};
  }

  :-moz-placeholder {
    color: ${standardColours.black};
  }
`;

const StyledIcon = styled(Svg)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
`;

const ProductWarrantySearchBox = ({
  refine,
  currentRefinement,
  onFocus,
  selectedProduct,
}) => (
  <StyledProductWarrantySearchBox>
    <StyledIcon image={searchIcon} />
    <StyledLabel htmlFor="search-term">Search product</StyledLabel>
    <StyledInput
      type="search"
      id="search-term"
      name="search-term"
      placeholder="Search product"
      onChange={e => refine(e.target.value)}
      value={selectedProduct ? selectedProduct : currentRefinement}
      onFocus={onFocus}
    />
  </StyledProductWarrantySearchBox>
);

export default connectSearchBox(ProductWarrantySearchBox);
